import './list.scss';
import dataApi from '@/api/manage/product.js';
import dataBankApi from '@/api/manage/bank.js';
import dataArticleApi from '@/api/manage/article.js';
import AreaSelect from '@/components/area-select/index.vue';

const EDITORCONF = [
  'head',
  'italic',
  'underline',
  'strikeThrough',
  'lineHeight',
  'backColor',
  'link',
  'todo',
  'justify',
  'quote',
  'image',
  'video',
  'table',
  'code',
  'splitLine',
  'undo',
  'redo'
];
export default {
  name: 'product-list',
  components: {
    AreaSelect
  },
  data () {
    const validatePass = (rule, value, callback) => {
      console.log(value);
      if (value === '') {
        callback(new Error('请输入'));
      } else if (!/^[0-9]+.?[0-9]*$/.test(value)) {
        callback(new Error('请输入数字'));
        value = '';
      } else {
        callback();
      }
    };
    const validatePass1 = (rule, value, callback) => {
      if (value.length <= 0) {
        callback(new Error('请选择'));
      } else {
        callback();
      }
    };
    return {
      SubmitId: '',
      screenDa: {},
      assist_drawer: false,
      screenOpData: {},
      screenOps: {
        labelWidth: 120,
        column: [
          {
            label: '纳税等级',
            prop: 'tax_rating',
            span: 12,
            type: 'checkbox',
            border: true,
            rules: [{ validator: validatePass1, trigger: 'blur' }],
            dicData: [
              {
                label: 'A',
                value: 'A'
              },
              {
                label: 'B',
                value: 'B'
              },
              {
                label: 'C',
                value: 'C'
              },
              {
                label: 'D',
                value: 'D'
              },
              {
                label: 'M',
                value: 'M'
              }
            ]
          },
          {
            label: '近期纳税时长',
            prop: 'pay_taxes_time',
            append: '（月）起',
            row: false,
            rules: [{ validator: validatePass, trigger: 'blur' }]
          },
          {
            label: '纳税额度',
            prop: 'tax_credits',
            append: '（万元）起',
            row: false,
            rules: [{ validator: validatePass, trigger: 'blur' }]
          },
          {
            label: '近期开票时长',
            prop: 'make_out_an_invoice',
            append: '（月）起',
            row: false,
            rules: [{ validator: validatePass, trigger: 'blur' }]
          },
          {
            label: '开票额度',
            prop: 'make_out_an_invoice_money',
            append: '（万元）起',
            row: false,
            rules: [{ validator: validatePass, trigger: 'blur' }]
          },
          {
            label: '身份',
            prop: 'client_identity',
            span: 12,
            type: 'checkbox',
            border: true,
            rules: [{ validator: validatePass1, trigger: 'blur' }],
            dicData: [
              {
                label: '法人',
                value: '1'
              },
              {
                label: '股东',
                value: '2'
              },
              {
                label: '个体工商户',
                value: '3'
              }
            ]
          },
          {
            label: '股东占股比例',
            prop: 'ratio_of_shareholding',
            append: '%',
            row: false,
            rules: [{ validator: validatePass, trigger: 'blur' }]
          },
          {
            label: '法人占股比例',
            prop: 'ratio_of_legal',
            append: '%',
            row: false,
            rules: [{ validator: validatePass, trigger: 'blur' }]
          },
          {
            label: '年龄',
            prop: 'mini_customer_age',
            append: '起',
            span: 6,
            row: false,
            rules: [{ validator: validatePass, trigger: 'blur' }]
          },
          {
            label: '年龄',
            prop: 'max_customer_age',
            append: '止',
            span: 6,
            row: false,
            rules: [{ validator: validatePass, trigger: 'blur' }]
          },
          {
            label: '注册时间',
            prop: 'registration_time',
            append: '月',
            row: false,
            rules: [{ validator: validatePass, trigger: 'blur' }]
          },
          {
            label: '变更',
            prop: 'change_the_time',
            append: '月',
            row: false,
            rules: [{ validator: validatePass, trigger: 'blur' }]
          }
        ]
      },
      dataApi: dataApi,
      page: {
        pageSize: 10,
        pageSizes: [10, 20, 30, 40, 50, 100]
      },
      dialogVisible: false,
      bankList: [],
      articleList: [],
      formDa: {
      },
      formOp: {
        column: [
          {
            type: 'input',
            prop: 'id',
            display: false
          },
          {
            label: '产品名称',
            type: 'input',
            prop: 'title',
            span: 8,
            rules: [
              {
                required: true,
                message: '产品名称不能为空'
              }
            ],
            required: true
          },
          {
            label: '供应商链接',
            type: 'input',
            prop: 'referral_link',
            span: 8,
            rules: [
              {
                required: true,
                message: '推广链接不能为空'
              }
            ],
            required: true
          },
          {
            label: '供应商',
            prop: 'bank_id',
            span: 8,
            formslot: true,
            rules: [
              {
                required: true,
                message: '请选择供应商',
                trigger: 'blur'
              }
            ]
          },
          {
            label: '所属分类',
            type: 'select',
            span: 8,
            display: true,
            dicFlag: true,
            prop: 'category_id',
            props: {
              label: 'label',
              value: 'value'
            },
            dicUrl: `${this.$baseUrl}general/get_category`,
            dicQuery: {
              scenario: 'product'
            },
            dicMethod: 'post',
            required: true,
            rules: [
              {
                required: true,
                message: '请选择所属分类'
              }
            ],
            dataType: 'number'
          },
          {
            label: '推荐位',
            type: 'select',
            span: 8,
            display: true,
            dicFlag: true,
            prop: 'recommend',
            props: {
              label: 'label',
              value: 'value'
            },
            dicUrl: `${this.$baseUrl}general/get_dict`,
            dicQuery: {
              dict_type: 'recommend'
            },
            dicMethod: 'post',
            dataType: 'string'
          },
          {
            type: 'input',
            label: '借贷周期',
            span: 8,
            display: true,
            prop: 'period',
            required: true,
            rules: [
              {
                required: true,
                message: '周期必须填写'
              }
            ],
            append: '期'
          },
          {
            label: '准入区域',
            type: 'input',
            span: 8,
            prop: 'area_field',
            sort: true
          },
          {
            label: '进件流程',
            prop: 'process',
            span: 8,
            formslot: true
          },
          {
            label: '进件须知',
            prop: 'notice',
            type: 'input',
            span: 8,
            bind: 'claim.notice'
          },
          {
            label: '醒目标记',
            type: 'select',
            prop: 'tags',
            dicData: [
              {
                label: '无',
                value: '0'
              },
              {
                label: '新品',
                value: 'xin1'
              },
              {
                label: '热门',
                value: 're'
              },
              {
                label: '推荐',
                value: 'tuijian9'
              }
            ],
            span: 8
          },
          {
            label: '采票链接',
            type: 'input',
            prop: 'pick_link',
            span: 8
          },
          {
            type: 'radio',
            label: '状态',
            value: 1,
            dicData: [
              {
                label: '禁用',
                value: 0
              },
              {
                label: '启用',
                value: 1
              }
            ],
            span: 8,
            display: true,
            prop: 'status',
            rules: [
              {
                required: true,
                message: '请选择状态'
              }
            ],
            required: true
          },
          {
            label: '额度',
            type: 'input',
            span: 6,
            display: true,
            prop: 'quota',
            rules: [
              {
                required: true,
                message: '额度必须填写'
              }
            ],
            required: true,
            append: '万元'
          },
          {
            type: 'input',
            label: '利息',
            span: 6,
            display: true,
            prop: 'rate',
            rules: [
              {
                required: true,
                message: '利息必须填写'
              }
            ],
            required: true,
            append: '%'
          },
          {
            type: 'input',
            label: '佣金比例',
            span: 6,
            display: true,
            prop: 'brokerage',
            required: true,
            rules: [
              {
                required: true,
                message: '佣金比例必须填写'
              }
            ]
          },
          {
            type: 'input',
            label: '最高佣金',
            span: 6,
            display: true,
            prop: 'max_rebate',
            required: true,
            rules: [
              {
                required: true,
                message: '最高佣金必须填写'
              }
            ],
            append: '元'
          },
          {
            label: '适用范围',
            type: 'checkbox',
            dicData: [
              {
                label: '个人',
                value: 1
              },
              {
                label: '企业',
                value: 2
              }
            ],
            span: 5,
            prop: 'scope',
            display: true,
            required: true,
            dataType: 'number',
            rules: [
              {
                required: true,
                message: '请选择适用范围'
              }
            ]
          },
          {
            label: '还款方式',
            type: 'checkbox',
            dicData: [
              {
                label: '随借随还',
                value: 1
              },
              {
                label: '等额本息',
                value: 2
              },
              {
                label: '等额本金',
                value: 3
              },
              {
                label: '先息后本',
                value: 4
              }
            ],
            span: 10,
            display: true,
            props: {
              label: 'label',
              value: 'value'
            },
            dataType: 'number',
            prop: 'repayment',
            rules: [
              {
                required: true,
                message: '请选择还款方式'
              }
            ],
            required: true
          },
          {
            type: 'input',
            label: '排序',
            span: 5,
            display: true,
            prop: 'sorting',
            required: true
          },
          {
            label: '返佣说明',
            type: 'ueditor',
            component: 'avue-ueditor',
            span: 24,
            display: true,
            prop: 'rebate_declare',
            readonly: false,
            options: {
              customConfig: {
                showFullScreen: false, // 是否全屏
                pasteFilterStyle: false, // 关闭样式过滤
                excludeMenus: EDITORCONF
              }
            }
          },
          {
            label: '申请材料',
            type: 'ueditor',
            component: 'avue-ueditor',
            span: 12,
            display: true,
            prop: 'material',
            maxlength: 1500,
            showWordLimit: true,
            readonly: false,
            bind: 'claim.material',
            options: {
              customConfig: {
                showFullScreen: false, // 是否全屏
                pasteFilterStyle: false, // 关闭样式过滤
                excludeMenus: EDITORCONF
              }
            }
          },
          {
            label: '企业要求',
            type: 'ueditor',
            component: 'avue-ueditor',
            span: 12,
            display: true,
            prop: 'enterprise',
            maxlength: 1500,
            showWordLimit: true,
            readonly: false,
            bind: 'claim.enterprise',
            options: {
              customConfig: {
                showFullScreen: false, // 是否全屏
                pasteFilterStyle: false, // 关闭样式过滤
                excludeMenus: EDITORCONF
              }
            }
          },
          {
            label: '个人要求',
            type: 'ueditor',
            component: 'avue-ueditor',
            span: 12,
            display: true,
            prop: 'personal',
            maxlength: 1500,
            showWordLimit: true,
            readonly: false,
            bind: 'claim.personal',
            options: {
              customConfig: {
                showFullScreen: false, // 是否全屏
                pasteFilterStyle: false, // 关闭样式过滤
                excludeMenus: EDITORCONF
              }
            }
          },
          {
            label: '征信要求',
            type: 'ueditor',
            component: 'avue-ueditor',
            span: 12,
            display: true,
            prop: 'credit',
            maxlength: 1500,
            showWordLimit: true,
            readonly: false,
            bind: 'claim.credit',
            options: {
              customConfig: {
                showFullScreen: false, // 是否全屏
                pasteFilterStyle: false, // 关闭样式过滤
                excludeMenus: EDITORCONF
              }
            }
          },
          {
            label: '备注要求',
            type: 'ueditor',
            component: 'avue-ueditor',
            span: 12,
            display: true,
            prop: 'note',
            maxlength: 1500,
            showWordLimit: true,
            readonly: false,
            bind: 'claim.note',
            options: {
              customConfig: {
                showFullScreen: false, // 是否全屏
                pasteFilterStyle: false, // 关闭样式过滤
                excludeMenus: EDITORCONF
              }
            }
          },
          {
            label: '禁入行业',
            type: 'ueditor',
            component: 'avue-ueditor',
            span: 12,
            display: true,
            prop: 'forbid',
            maxlength: 2000,
            showWordLimit: true,
            readonly: false,
            options: {
              customConfig: {
                showFullScreen: false, // 是否全屏
                pasteFilterStyle: false, // 关闭样式过滤
                excludeMenus: EDITORCONF
              }
            }
          }
        ],
        disabled: false,
        clearExclude: ['status'],
        labelPosition: 'right',
        labelSuffix: ':',
        labelWidth: 110,
        gutter: 0,
        menuBtn: true,
        submitBtn: true,
        submitText: '提交',
        emptyBtn: false,
        emptyText: '清空',
        menuPosition: 'center'
      },
      tableData: [],
      tbleOption: {
        total: 10,
        align: 'center',
        cellBtn: false,
        cancelBtn: false,
        addBtn: false,
        ...this.$store.getters.tableConfig,
        editBtn: false,
        selection: false,
        delBtn: false,
        column: [
          {
            label: '产品名称',
            prop: 'title',
            type: 'input',
            search: true
          },
          {
            type: 'select',
            label: '所属分类',
            display: true,
            props: {
              label: 'label',
              value: 'value'
            },
            prop: 'category_id',
            dicFlag: true,
            multiple: true,
            search: true,
            dicUrl: `${this.$baseUrl}general/get_category`,
            dicQuery: {
              scenario: 'product'
            },
            dicMethod: 'post',
            dataType: 'number'
          },
          {
            label: '供应商',
            prop: 'bank',
            bind: 'bank.title'
          },
          {
            label: '供应商',
            prop: 'bank_id',
            type: 'table',
            span: 12,
            dialogWidth: '60%',
            dataType: 'number',
            search: true,
            hide: true,
            children: {
              border: true,
              column: [
                {
                  label: '编号',
                  prop: 'id',
                  width: 80
                },
                {
                  label: '标识',
                  prop: 'logo',
                  width: 120,
                  type: 'img'
                },
                {
                  label: '名称',
                  search: true,
                  searchSpan: 24,
                  prop: 'title',
                  align: 'left'
                }
              ]
            },
            formatter: row => {
              if (!row.title) return '';
              return `${row.title}`;
            },
            onLoad: ({ page, value, data }, callback) => {
              // 首次加载去查询对应的值
              if (value) {
                const postData = {
                  id: value
                };
                dataBankApi.getOne.r(postData).then(res => {
                  callback({
                    ...res.data
                  });
                });
                return;
              }
              const postData = {
                page: page.currentPage,
                pageSize: page.pageSize,
                ...data
              };
              dataBankApi.getList.r(postData).then(res => {
                callback({
                  total: res.data.total,
                  data: res.data.list
                });
              });
            },
            props: {
              label: 'name',
              value: 'id'
            },
            rules: [
              {
                required: true,
                message: '请选择供应商',
                trigger: 'blur'
              }
            ]
          },
          {
            type: 'radio',
            label: '状态',
            value: 1,
            dicData: [
              {
                label: '禁用',
                value: 0
              },
              {
                label: '启用',
                value: 1
              }
            ],
            display: true,
            search: 'true',
            prop: 'status'
          },
          {
            // 创建者 create_by
            label: '创建者',
            addDisplay: false,
            editDisplay: false,
            prop: 'create_user'
          },
          {
            // 创建时间 create_time
            label: '创建时间',
            addDisplay: false,
            editDisplay: false,
            prop: 'create_time'
          },
          {
            // 更新者 update_by
            label: '更新者',
            addDisplay: false,
            editDisplay: false,
            prop: 'update_user'
          },
          {
            // 更新时间 update_time
            label: '更新时间',
            addDisplay: false,
            editDisplay: false,
            prop: 'update_time'
          }
        ]
      },
      searchData: {},
      // 辅助搜索
      screenOp: {
        column: [
          {
            type: 'input',
            prop: 'id',
            display: false
          }
        ],
        disabled: false,
        clearExclude: ['status'],
        labelPosition: 'right',
        labelSuffix: ':',
        labelWidth: 110,
        gutter: 0,
        menuBtn: true,
        submitBtn: true,
        submitText: '提交',
        emptyBtn: false,
        emptyText: '清空',
        menuPosition: 'center'
      },
      reload: Math.random()
    };
  },
  computed: {},
  mounted () {},
  filters: {},
  methods: {
    // 分页
    onLoad (page) {
      this.page = page;
      this.getList();
    },
    // 筛选
    searchChange (params, done) {
      this.searchData = params;
      this.page.currentPage = 1;
      this.getList();
      done();
    },
    // 筛选清空
    searchReset () {
      this.page.currentPage = 1;
      this.searchData = {};
      this.getList();
    },
    rowView (row) {
      this.getInfo(row);
      this.dialogVisible = true;
      this.formOp.disabled = true;
      this.formOp.submitBtn = false;
      this.formOp.emptyBtn = false;
    },
    // 添加
    addDialogForm (row) {
      this.formDa = {
        id: ''
      };
      if (row.id !== undefined) {
        this.getInfo(row);
      }
      this.dialogVisible = true;
      this.formOp.disabled = false;
      this.formOp.submitBtn = true;
      this.formOp.emptyBtn = true;
      this.reload = Math.random();
    },
    drawerClose () {
      this.dialogVisible = false;
      this.bankList = [];
      this.$refs.form.resetForm();
    },
    handleSubmit (form, done) {
      form.area_idx = JSON.stringify(form.area_idx);
      
      if (form.id) {
        dataApi.update
          .r(form)
          .then(() => {
            this.getList();
            this.drawerClose();
            this.$message.success('更新成功');
            done();
          })
          .catch(() => {
            done();
          });
      } else {
        dataApi.add
          .r(form)
          .then(() => {
            this.getList();
            this.drawerClose();
            this.$message.success('新增成功');
            done();
          })
          .catch(err => {
            done();
            console.log(err);
          });
      }
    },
    // 删除
    rowDel (form) {
      this.$confirm('此操作将永久删除该数据, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        dataApi.del.r({ id: form.id }).then(res => {
          console.log(res);
          this.getList();
          this.$message.success('删除成功');
        });
      });
    },
    getInfo (row) {
      dataApi.getOne.r({ id: row.id }).then(res => {
        this.formDa = res.data;
        this.screenOpData = res.data;
        if (this.screenOpData.tax_rating) {
          this.screenOpData.tax_rating = this.screenOpData.tax_rating.split(',');
        }
        if (this.screenOpData.client_identity) {
          this.screenOpData.client_identity = this.screenOpData.client_identity.split(',');
        }
        if (res.data.bank) {
          this.bankList = [];
          this.bankList.push(res.data.bank);
        }
        if (res.data.process) {
          this.articleList = [];
          this.articleList.push(res.data.processInfo);
        }
      });
    },
    getList () {
      const postData = {
        page: this.page.currentPage,
        pageSize: this.page.pageSize,
        ...this.searchData
      };
      dataApi.getList.r(postData).then(res => {
        this.tableData = res.data.list;
        this.page.total = res.data.total;
      });
    },
    bankChangeSelect () {},
    getBank (query) {
      if (query !== '') {
        const postData = {
          title: query
        };
        dataBankApi.getList.r(postData).then(res => {
          this.bankList = res.data.list;
        });
      }
    },
    articleChangeSelect () {},
    getArticle (query) {
      if (query !== '') {
        const postData = {
          title: query
        };
        dataArticleApi.getList.r(postData).then(res => {
          this.articleList = res.data.list;
        });
      }
    },
    AiDrawer () {
      this.$refs.aiForm.resetForm();
      this.assist_drawer = false;
      this.reload = Math.random();
      // console.log(this.$refs.aiForm, '888555888');
    },
    AI_assist (form) {
      this.getInfo(form);
      this.assist_drawer = true;
    },
    AI_submit (aiForm, done) {
      // done();
      // console.log(this.screenOpData);
      // data.tax_ratin.toString();
      const data = aiForm;
      this.screenDa = {
        id: data.id,
        tax_rating: data.tax_rating.toString(),
        pay_taxes_time: data.pay_taxes_time,
        tax_credits: data.tax_credits,
        make_out_an_invoice: data.make_out_an_invoice,
        make_out_an_invoice_money: data.make_out_an_invoice_money,
        client_identity: data.client_identity.toString(),
        ratio_of_shareholding: data.ratio_of_shareholding,
        ratio_of_legal: data.ratio_of_legal,
        mini_customer_age: data.mini_customer_age,
        max_customer_age: data.max_customer_age,
        registration_time: data.registration_time,
        change_the_time: data.change_the_time
      };
      dataApi.aiupdate
        .r(this.screenDa)
        .then(() => {
          this.getList();
          this.AiDrawer();
          this.assist_drawer = false;
          this.$message.success('更新成功');
          done();
        })
        .catch(err => {
          console.log(err);
        });
    },
    setArea (ids) {
      // 设置准入区域
      this.formDa.area_field = ids;
    }
  }
};
