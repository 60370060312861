import './list.scss';
import dataApi from '@/api/manage/bank.js';

export default {
  name: 'bank-list',
  components: {},
  data () {
    return {
      page: {
        pageSize: 10,
        pageSizes: [10, 20, 30, 40, 50, 100]
      },
      tableData: [],
      tbleOption: {
        total: 10,
        align: 'center',
        cellBtn: false,
        cancelBtn: false,
        ...this.$store.getters.tableConfig,
        column: [
          {
            label: '名称',
            prop: 'title',
            type: 'input',
            required: true,
            span: 24,
            rules: [
              {
                required: true,
                message: '名称不能为空'
              }
            ]
          },
          {
            label: 'logo',
            prop: 'logo',
            type: 'upload',
            limit: 1,
            span: 24,
            addDisplay: true,
            editDisplay: true,
            hide: true,
            propsHttp: {},
            dataType: 'string',
            listType: 'picture',
            accept: 'jpg/png',
            tip: '只能上传jpg/png文件，且不超过500kb',
            oss: 'ali',
            required: true,
            rules: [
              {
                required: true,
                message: '图片必须上传'
              }
            ]
          },
          {
            label: '说明',
            prop: 'remark',
            type: 'textarea',
            span: 24
          },
          {
            type: 'radio',
            label: '状态',
            value: 1,
            dicData: [
              {
                label: '禁用',
                value: 0
              },
              {
                label: '启用',
                value: 1
              }
            ],
            span: 24,
            display: true,
            prop: 'status',
            rules: [
              {
                required: true,
                message: '请选择状态'
              }
            ],
            required: true
          },
          {
            // 创建者 create_by
            label: '创建者',
            addDisplay: false,
            editDisplay: false,
            prop: 'create_user'
          },
          {
            // 创建时间 create_time
            label: '创建时间',
            addDisplay: false,
            editDisplay: false,
            prop: 'create_time'
          },
          {
            // 更新者 update_by
            label: '更新者',
            addDisplay: false,
            editDisplay: false,
            prop: 'update_user'
          },
          {
            // 更新时间 update_time
            label: '更新时间',
            addDisplay: false,
            editDisplay: false,
            prop: 'update_time'
          }
        ]
      },
      searchData: {}
    };
  },
  computed: {},
  mounted () {
  },
  filters: {},
  methods: {
    // 分页
    onLoad (page) {
      this.page = page;
      this.getList();
    },
    // 筛选
    searchChange (params, done) {
      this.searchData = params;
      this.page.currentPage = 1;
      this.getList();
      done();
    },
    // 筛选清空
    searchReset () {
      this.page.currentPage = 1;
      this.searchData = {};
      this.getList();
    },
    // 添加
    handleSubmit (form, done, loading) {
      console.log('form', form, loading);
      dataApi.add.r(form).then(() => {
        done(form);
        this.getList();
        loading();
      });
      done();
    },
    // 删除
    rowDel (form) {
      this.$confirm('此操作将永久删除该数据, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        console.log('删除数据', form);
        dataApi.del.r({ id: form.id }).then(() => {
          this.getList();
          this.$message.success('删除成功');
        });
      });
    },
    // 修改
    rowUpdate (form, index, done, loading) {
      console.log('index', form, index);
      dataApi.update.r(form).then(() => {
        done(form);
        this.getList();
        loading();
      });
      done();
    },
    // 获取
    getList () {
      const postData = {
        page: this.page.currentPage,
        pageSize: this.page.pageSize,
        ...this.searchData
      };
      dataApi.getList.r(postData).then(res => {
        console.log('接口返回', res);
        this.tableData = res.data.list;
        this.page.total = res.data.total;
      });
    }
  }
};
