<template>
<div class="area">
  <div>
    <el-button type="text" @click="centerDialogVisible = true">设置准入区域</el-button>
  </div>

  <el-dialog title="设置准入区域"
      :visible.sync="centerDialogVisible"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      :modal="false"
      width="80%">
      <div class="body">
        <!-- <el-checkbox :indeterminate="isIndeterminate" v-model="checkAll" @change="handleCheckAllChange">全国</el-checkbox>
        <div style="margin: 15px 0;"></div> -->
        <el-checkbox-group v-model="selectedCities">
          <el-popover placement="right" width="200"
            v-for="(city, index) in listData"
            :key="index"
            :visible-arrow="false"
            trigger="hover"
          >
            <div class="child">
              <div class="title">{{city.label}}</div>
              <el-checkbox v-for="(child,inx) in city.children"
                :label="child.value"
                :key="child.value+inx"
                @change="handleCheckedCitiesChange(child)">{{child.label}}</el-checkbox>
            </div>
            <el-checkbox :label="city.value" slot="reference" @change="handleCheckedCitiesChange(city)">{{city.label}}</el-checkbox>
          </el-popover>
        </el-checkbox-group>
      </div>
    <span slot="footer" class="dialog-footer">
      <el-button @click="centerDialogVisible = false">取 消</el-button>
      <el-button type="primary" @click="handleSubmit">确 定</el-button>
    </span>
  </el-dialog>
</div>

</template>
<script>
import dataApi from '@/api/manage/area.js';
/** 设置准入区域 */
export default {
  name: 'areaSelect',
  props: {
    selectAreaDa: {
      type: String,
      default () {
        return '';
      }
    }
  },
  data () {
    return {
      visible: false,
      centerDialogVisible: false,
      listData: [],
      checkedCities: [],
      selectedCities: []
    };
  },
  created () {
    this.getList();
  },
  watch: {
    selectAreaDa (val) {
      const arr = val.split(',');
      const result = Array.from(new Set(arr)).map(Number);
      // this.checkedCities = result;
      this.selectedCities = result;
      console.log(this.selectedCities);
    }
  },
  methods: {
    getList () {
      const that = this;
      dataApi.getAllArea
        .r({})
        .then((res) => {
          // console.log(res.data);
          that.listData = res.data;
        })
        .catch(err => {
          done();
          console.log(err);
        });
    },
    handleChildShow (child) {
      console.log(child);
      this.visible = true;
    },
    handleCheckedCitiesChange (e) {
      let checked = false;
      // 判断是新增还是删除
      if (this.selectedCities.indexOf(e.value) > -1) {
        checked = true;
      }
      // 判断是否存在子集
      if (e.children) {
        // console.log(e.children);
        if (checked) {
          e.children.forEach(child => {
            this.selectedCities.push(child.value);
          });
        } else {
          e.children.forEach(child => {
            this.selectedCities.forEach((item, index) => {
              if (child.value === item) {
                this.selectedCities.splice(index, 1);
              }
            });
          });
        }
      } else {
        // 获取当前选中项的父级节点详情
        const parent = this.listData.find(item => item.value === e.parent_id);

        if (parent) {
          if (!checked) {
            // parent中子集是否有被选中项，无则删除父级节点选中状态
            const hasChecked = parent.children.some(child => this.selectedCities.indexOf(child.value) > -1);

            if (!hasChecked) {
              this.selectedCities.splice(this.selectedCities.indexOf(parent.value), 1);
            }
          } else {
            // 当前选中项的父级的子集有选中，且父级未被选中则选中父级
            // eslint-disable-next-line no-lonely-if
            if (this.selectedCities.indexOf(parent.value) === -1) {
              this.selectedCities.push(parent.value);
            } else {
              // 当前选中项的父级的子集有选中，且父级已被选中则不做处理
            }
          }
        }
      }
    },
    handleSubmit () {
      console.log(this.selectedCities);
      this.centerDialogVisible = false;
      this.$emit('setArea', this.selectedCities.toString());
    }
  }
};
</script>
<style scoped>
.header{
  color: #fff;
  background-color: blue
}
.el-checkbox{
  width: 120px;
  margin-bottom: 5px;
}
.child{
  padding:20px
}
.dialog-footer{
  text-align: center;
}
</style>
