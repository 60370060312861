var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:{ 'page-class': !_vm.$store.getters.tableConfig.card }},[_c('avue-crud',{ref:"crud",attrs:{"data":_vm.tableData,"option":_vm.tbleOption,"page":_vm.page},on:{"refresh-change":_vm.getList,"row-del":_vm.rowDel,"on-load":_vm.onLoad,"search-change":_vm.searchChange,"search-reset":_vm.searchReset,"update:page":function($event){_vm.page=$event}},scopedSlots:_vm._u([{key:"menu",fn:function(ref){
var type = ref.type;
var size = ref.size;
var row = ref.row;
var index = ref.index;
return [_c('el-button',{attrs:{"icon":"el-icon-view","size":size,"type":type},on:{"click":function($event){$event.stopPropagation();return _vm.rowView(row,index)}}},[_vm._v("查看")]),_c('el-button',{attrs:{"icon":"el-icon-edit-outline","size":size,"type":type},on:{"click":function($event){$event.stopPropagation();return _vm.addDialogForm(row,index)}}},[_vm._v("编辑")]),_c('el-button',{attrs:{"icon":"el-icon-s-operation","size":size,"type":type},on:{"click":function($event){$event.stopPropagation();return _vm.AI_assist(row,index)}}},[_vm._v("AI辅助")]),_c('el-button',{attrs:{"icon":"el-icon-delete","size":size,"type":type},on:{"click":function($event){$event.stopPropagation();return _vm.rowDel(row,index)}}},[_vm._v("删除")])]}}])},[_c('template',{slot:"menuLeft"},[_c('el-button',{attrs:{"icon":"el-icon-plus","type":"primary","size":"small"},on:{"click":function($event){$event.stopPropagation();return _vm.addDialogForm.apply(null, arguments)}}},[_vm._v("添加产品")])],1)],2),_c('el-drawer',{attrs:{"title":"添加/编辑产品","before-close":_vm.drawerClose,"visible":_vm.dialogVisible,"direction":"ttb","size":"100%"},on:{"update:visible":function($event){_vm.dialogVisible=$event}}},[_c('avue-form',{key:_vm.reload,ref:"form",attrs:{"option":_vm.formOp},on:{"submit":_vm.handleSubmit},scopedSlots:_vm._u([{key:"bank_id",fn:function(ref){
var disabled = ref.disabled;
return [_c('el-select',{attrs:{"filterable":"","remote":"","disabled":disabled,"placeholder":"请输入关键词","suffix-icon":"el-icon-search","remote-method":_vm.getBank},on:{"visible-change":_vm.bankChangeSelect},model:{value:(_vm.formDa.bank_id),callback:function ($$v) {_vm.$set(_vm.formDa, "bank_id", $$v)},expression:"formDa.bank_id"}},_vm._l((_vm.bankList),function(item){return _c('el-option',{key:item.id,attrs:{"label":item.title,"value":item.id}})}),1)]}},{key:"process",fn:function(ref){
var disabled = ref.disabled;
return [_c('el-select',{attrs:{"filterable":"","remote":"","disabled":disabled,"placeholder":"请输入关键词","suffix-icon":"el-icon-search","remote-method":_vm.getArticle},on:{"visible-change":_vm.articleChangeSelect},model:{value:(_vm.formDa.process),callback:function ($$v) {_vm.$set(_vm.formDa, "process", $$v)},expression:"formDa.process"}},_vm._l((_vm.articleList),function(item){return _c('el-option',{key:item.id,attrs:{"label":item.title,"value":item.id}})}),1)]}},{key:"area_field",fn:function(ref){return [_c('AreaSelect',{attrs:{"selectAreaDa":_vm.formDa.area_field},on:{"setArea":_vm.setArea}})]}}]),model:{value:(_vm.formDa),callback:function ($$v) {_vm.formDa=$$v},expression:"formDa"}})],1),_c('el-drawer',{attrs:{"size":"100%","title":"AI辅助","before-close":_vm.AiDrawer,"visible":_vm.assist_drawer,"direction":"ttb"},on:{"update:visible":function($event){_vm.assist_drawer=$event}}},[_c('avue-form',{key:_vm.reload,ref:"aiForm",attrs:{"option":_vm.screenOps},on:{"submit":_vm.AI_submit},model:{value:(_vm.screenOpData),callback:function ($$v) {_vm.screenOpData=$$v},expression:"screenOpData"}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }