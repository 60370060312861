<template>
  <div :class="{ 'page-class': !$store.getters.tableConfig.card }">
    <avue-crud ref="crud"
               :data="tableData"
               :option="tbleOption"
               @refresh-change="getList"
               @row-del="rowDel"
               @on-load="onLoad"
               @search-change="searchChange"
               @search-reset="searchReset"
               :page.sync="page">
      <template slot="menuLeft">

        <el-button icon="el-icon-plus"
                   type="primary"
                   size="small"
                   @click.stop="addDialogForm">添加产品</el-button>
      </template>
      <template slot-scope="{type,size,row,index}"
                slot="menu">
        <el-button icon="el-icon-view"
                   :size="size"
                   :type="type"
                   @click.stop="rowView(row,index)">查看</el-button>
        <el-button icon="el-icon-edit-outline"
                   :size="size"
                   :type="type"
                   @click.stop="addDialogForm(row,index)">编辑</el-button>
        <el-button icon="el-icon-s-operation"
                   :size="size"
                   :type="type"
                   @click.stop="AI_assist(row,index)">AI辅助</el-button>
        <el-button icon="el-icon-delete"
                   :size="size"
                   :type="type"
                   @click.stop="rowDel(row,index)">删除</el-button>
      </template>
    </avue-crud>

    <el-drawer title="添加/编辑产品"
               :before-close="drawerClose"
               :visible.sync="dialogVisible"
               direction="ttb"
               size="100%">
      <avue-form ref="form"
                 v-model="formDa"
                 :option="formOp"
                 :key="reload"
                 @submit="handleSubmit">
        <template slot-scope="{disabled}"
                  slot="bank_id">
          <el-select v-model="formDa.bank_id"
                     filterable
                     remote
                     :disabled="disabled"
                     placeholder="请输入关键词"
                     suffix-icon="el-icon-search"
                     @visible-change="bankChangeSelect"
                     :remote-method="getBank">
            <el-option v-for="item in bankList"
                       :key="item.id"
                       :label="item.title"
                       :value="item.id">
            </el-option>
          </el-select>
        </template>
        <template slot-scope="{disabled}"
                  slot="process">
          <el-select v-model="formDa.process"
                     filterable
                     remote
                     :disabled="disabled"
                     placeholder="请输入关键词"
                     suffix-icon="el-icon-search"
                     @visible-change="articleChangeSelect"
                     :remote-method="getArticle">
            <el-option v-for="item in articleList"
                       :key="item.id"
                       :label="item.title"
                       :value="item.id">
            </el-option>
          </el-select>
        </template>
        <template slot-scope="{}"
                  slot="area_field">
          <AreaSelect :selectAreaDa="formDa.area_field"
                      @setArea="setArea"></AreaSelect>
        </template>
      </avue-form>
    </el-drawer>
    <el-drawer size="100%"
               title="AI辅助"
               :before-close="AiDrawer"
               :visible.sync="assist_drawer"
               direction="ttb">
      <avue-form ref="aiForm"
                 :option="screenOps"
                 @submit="AI_submit"
                 :key="reload"
                 v-model="screenOpData">
      </avue-form>
    </el-drawer>
  </div>
</template>
<script>
import List from './list';

export default List;
</script>
